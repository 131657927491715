import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routing from '../../routes';
import configureStore from '../../store/configureStore';
import SSO from '../SSO';

const { store, persistor } = configureStore({});
const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <SSO>
          <Routing />
        </SSO>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
